import React from "react"
import styled from "styled-components"

const PageHeadingStyles = styled.div`
  padding-top: 60px;
  background-color: #c5cacf;
  #content-container {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    @media (max-width: 1000px) {
      width: 100%;
      font-size: smaller;
    }
    #heading {
      display: flex;
      justify-content: center;
      align-content: center;

      h1 {
        margin: 0;
        font-size: 2.5em;
        font-weight: bold;
        text-align: center;

        padding: .5rem 0px;
        @media (max-width: 1070px) {
          font-size: 2em;
        }

        @media (max-width: 525px) {
          font-size: 1.5em;
        }

        @media (max-width: 400px) {
          font-size: 1.2em;
          font-weight: bold;
        }
      }

      h2 {
        margin: 0;
        font-size: 2.5em;
        text-align: center;
        font-weight: bold;

        @media (max-width: 1070px) {
          font-size: 2em;
        }
        @media (max-width: 525px) {
          font-size: 1.5em;
        }

        @media (max-width: 400px) {
          font-size: 1.2em;
          font-weight: bold;
        }
      }
    }

    /* h1 {
      font-weight: bold;
      text-align: center;
    }

    h2 {
      font-weight: bold;
      text-align: center;
    } */

    p {
      padding: 1rem;
      text-align: center;
      margin: 0;
    }
  }
`

const PageHeading = props => {
  return (
    <div>
      <PageHeadingStyles>
        <div id="content-container">
          <div id="heading">
            <h1>{props.subHeading} - </h1>
            <h1> {props.title}</h1>
          </div>
          {props.title1 || props.path || props.time || props.tank || props.title3 || props.title2 ? 
          <p>
            <b>{props.title1} </b> {props.path} <b>{props.title2} </b>
            {props.tank} <b>{props.title3} </b>
            {props.time}
          </p>
          :""}

          {/* <p>{props.description}</p> */}
        </div>
      </PageHeadingStyles>
    </div>
  )
}

export default PageHeading
