import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const ProductCardStyles = styled.div`
  #card-container {
    width: 300px;
    /* height: 450px; */
    border: 1px solid #5595f7;
    overflow: hidden;
    margin: 2rem;

    #img-container {
      width: 100%;
      height: 60%;

      img {
        padding: 1rem;
        width: 90%;
        /* height: 80%; */
        object-fit: cover;
      }
    }

    #productInfo-container {
      position: relative;
      /* padding: 1rem; */
      margin: 0;
      text-align: center;
      width: 100%;
      height: 100%;
      background-color: #5595f7;
      color: white;
      overflow: hidden;

      p {
        margin: 0;
      }

      button {
        background-color: #5595f7;
        border: 1px solid black;
        padding: 0.7rem;
        margin: 1rem;
        color: white;

        &:hover {
          background-color: orange;
          cursor: pointer;
          transition-duration: 0.5s;
        }
      }
    }
  }
`

const ProductCard = props => {
  return (
    <ProductCardStyles>
      <Link style={{ textDecoration: "none" }} to={props.link}>
        <div id="card-container">
          <div id="img-container">
            <img src={props.image} alt={props.alt} />
          </div>
          <div id="productInfo-container">
            <h3 style={{ fontSize: props.titleSize }}>{props.title}</h3>
            <p>{props.description}</p>
            <p>{props.tank}</p>
            <p>{props.time}</p>

            <button>Learn More</button>
          </div>
        </div>
      </Link>
    </ProductCardStyles>
  )
}

export default ProductCard
